import React, { useState } from "react";
import { AiFillCopy } from "react-icons/ai";
import { FaPray, FaRegNewspaper, FaUsers } from "react-icons/fa";
import { GiFlowerStar, GiPayMoney, GiSkills, GiShoppingBag } from "react-icons/gi";
import { IoIosWallet } from "react-icons/io";
import { IoLanguageSharp } from "react-icons/io5";
import { MdCategory, MdDashboard, MdLocalOffer, MdOutlineArticle, MdOutlineRateReview, MdPhoto } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { FaVideo } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/icons/logo.svg";
import s from "./sidebar.module.css";


const Sidebar = ({ open }) => {
  const navigate = useNavigate();

  const [menuItems, setmenuItems] = useState([
    {
      title: "Dashboard",
      icons: <MdDashboard size={20} />,
      navigate: "dashboard",
      show: false,
      subItems: [],
    },
    {
      title: "Role",
      icons: <MdOutlineArticle size={20} />,
      navigate: "role",
      show: false,
      subItems: [],
    },
    {
      title: "Admin",
      icons: <RiAdminFill size={20} />,
      navigate: "admin",
      show: false,
      subItems: [],
    },
    {
      title: "Astrologer",
      icons: <GiFlowerStar size={20} />,
      navigate: "astrologer-list",
      show: false,
      subItems: [],
    },
    {
      title: "Slabs",
      icons: <AiFillCopy size={20} />,
      navigate: "astrologer-slab",
      show: false,
      subItems: [],
    },
    {
      title: "Users",
      icons: <FaUsers size={20} />,
      navigate: "users",
      show: false,
      subItems: [],
    },

    {
      title: "Puja",
      icons: <FaPray size={20} />,
      navigate: "astro-puja",
      show: false,
      subItems: [],
    },
    {
      title: "Puja Orders",
      icons: <GiShoppingBag size={20} />,
      navigate: "puja-orders",
      show: false,
      subItems: [],
    },
    {
      title: "Live Stream",
      icons: <FaVideo size={20} />,
      navigate: "current-past-streams",
      show: false,
      subItems: [], 
    },
    {
      title: "Skill",
      icons: <GiSkills size={20} />,
      navigate: "skill-list",
      show: false,
      subItems: [],
    },
    {
      title: "Language",
      icons: <IoLanguageSharp size={20} />,
      navigate: "language",
      show: false,
      subItems: [],
    },
    {
      title: "Category",
      icons: <MdCategory size={20} />,
      navigate: "category-list",
      show: false,
      subItems: [],
    },
    {
      title: "Banner",
      icons: <MdPhoto size={20} />,
      navigate: "banner-list",
      show: false,
      subItems: [],
    },
    {
      title: "Testimonial",
      icons: <MdOutlineRateReview size={20} />,
      navigate: "testimonial-list",
      show: false,
      subItems: [],
    },
    {
      title: "Offers",
      icons: <MdLocalOffer size={20} />,
      navigate: "offers_list",
      show: false,
      subItems: [],
    },
    {
      title: "News",
      icons: <FaRegNewspaper size={20} />,
      navigate: "news_list",
      show: false,
      subItems: [],
    },
    {
      title: "Wallet",
      icons: <IoIosWallet size={20} />,
      navigate: "wallet",
      show: false,
      subItems: [],
    },
    {
      title: "Settlement",
      icons: <GiPayMoney size={20} />,
      navigate: "astrologer-invoices",
      show: false,
      subItems: [],
    },
  ]);

  return (
    <>
      <section className={s["sidebar"]}>
        <div className={`${s["collapsed-logo"]} ${open ? `${s["logo-section"]}` : `${s["logo-section-hide"]}`}`}>
          <img src={logo} alt="logo" draggable="false" />
        </div>
        {menuItems.map((data, i) => (
          <div key={'side-'+i} className={s["sidebar-content"]}>
            <div className={s["sidebar-item"]} onClick={() => {
                if(data?.navigate === 'astrologer-invoices'){
                  navigate(`/${data?.navigate}`, {state: {}})
                }else{
                  navigate(`/${data?.navigate}`)
                }
              }}>
              <div className="sidebaricons">{data.icons}</div>
              <div className={open ? `${s["sidebar-title"]}` : `${s["sidebar-title-hide"]}`}>{data.title}</div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default Sidebar;